import React, { useEffect } from 'react';
import { Container } from '@mui/system';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import api from '../../service/api';
import '../../css/Listas/ListaProfissional.modules.css';

const fetchProfissionais = async (dispatch) => {
  try {
    const response = await api.get('/Profissionais/Listar');
    const dadosProfissionais = response.data.resultado;
    dispatch({ type: 'SET_PROFISSIONAIS', payload: dadosProfissionais });
  } catch (error) {
    console.error(error);
  }
};

const ListaProfissionais = () => {
  const profissionais = useSelector(state => state.profissionais);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    fetchProfissionais(dispatch);
  }, []);

  useEffect(() => {
    dispatch({ type: 'SET_PROFISSIONAL_SELECIONADO', payload: null });
  }, []);

  const handleEditarProfissional = (profissional) => {
    dispatch({ type: 'SET_PROFISSIONAL_SELECIONADO', payload: profissional });
    navigate('/cadastro-profissional');
  };

  const handleDeletarProfissional = async (profissional) => {
    try {
      const idProfissional = profissional.id;
      const novoStatus = profissional.ativo ? 'inativo' : 'ativo';

      await api.delete(`/Profissionais/Ativar/Inativar?id=${idProfissional}`);

      dispatch({
        type: 'SET_STATUS_PROFISSIONAL',
        payload: {
          id: idProfissional,
          ativo: novoStatus === 'ativo',
        },
      });
      fetchProfissionais(dispatch);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <Container maxWidth="xd">
        <div className="tituloLista">
          <h1>Lista de Profissionais</h1>
        </div>
        <div className="btnCadastro">
          <Link to="/cadastro-profissional" className="btnCadProfissional">Cadastrar Profissional</Link>
        </div>
        <br />
        <table className="tabelaProfissionais">
          <thead>
            <tr>
              <th>Nome Fonético</th>
              <th>CPF</th>
              <th>Número do Conselho</th>
              <th>Status</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            {profissionais.map((profissional) => (
              <tr key={profissional.id}>
                <td>{profissional.nome_fonetico}</td>
                <td>{profissional.cpf}</td>
                <td>{profissional.numero_conselhos}</td>
                <td>{profissional.ativo ? "Ativo" : "Desabilitado"}</td>
                <td>
                  <button className="btnEditar" onClick={() => handleEditarProfissional(profissional)}>
                    Editar
                  </button>
                  <button className="btnExcluir" onClick={() => handleDeletarProfissional(profissional)}>
                    Ativo/Desabilitado
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Container>
      <br />
      <br />
    </div>
  );
};

export default ListaProfissionais;
