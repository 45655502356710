import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Especialidades from './pages/Especialidades.js';
import Usuario from './pages/Usuario.js';
import Profissional from './pages/Profissional.js';
import Inicial from './pages/Inicial.js';
import CadEspecialidades from './components/Cadastros/CadEspecialidades';
import CadProfissional from './components/Cadastros/CadProfissional';
import CadUsuario from './components/Cadastros/CadUsuario';

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Inicial />} />
          <Route path="/especialidades" element={<Especialidades />} />
          <Route path="/usuario" element={<Usuario />} />
          <Route path="/profissional" element={<Profissional />} />
          <Route path="/cadastro-especialidades" element={<CadEspecialidades />} />
          <Route path="/cadastro-profissional" element={<CadProfissional />} />
          <Route path="/cadastro-usuario" element={<CadUsuario />} />
        </Routes>

      </BrowserRouter>
    </div>
  );
}

export default App;
