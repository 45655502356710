import { createStore } from 'redux';

const initialState = {
  especialidades: [],
  especialidadeSelecionada: null,
  usuarios: [],
  usuarioSelecionado: null,
  profissionais: [],
  profissionalSelecionado: null,
};

const SET_ESPECIALIDADES = 'SET_ESPECIALIDADES';
const SET_ESPECIALIDADE_SELECIONADA = 'SET_ESPECIALIDADE_SELECIONADA';
const SET_USUARIOS = 'SET_USUARIOS';
const SET_USUARIO_SELECIONADO = 'SET_USUARIO_SELECIONADO';
const SET_PROFISSIONAIS = 'SET_PROFISSIONAIS';
const SET_PROFISSIONAL_SELECIONADO = 'SET_PROFISSIONAL_SELECIONADO';
const SET_STATUS_PROFISSIONAL = 'SET_STATUS_PROFISSIONAL';
const SET_STATUS_USUARIO = 'SET_STATUS_USUARIO';

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ESPECIALIDADES:
      return {
        ...state,
        especialidades: action.payload,
      };
    case SET_ESPECIALIDADE_SELECIONADA:
      return {
        ...state,
        especialidadeSelecionada: action.payload,
      };
    case SET_USUARIOS:
      return {
        ...state,
        usuarios: action.payload,
      };
    case SET_USUARIO_SELECIONADO:
      return {
        ...state,
        usuarioSelecionado: action.payload,
      };
    case SET_PROFISSIONAIS:
      return {
        ...state,
        profissionais: action.payload,
      };
    case SET_PROFISSIONAL_SELECIONADO:
      return {
        ...state,
        profissionalSelecionado: action.payload,
      };
    case SET_STATUS_PROFISSIONAL:
      const { id, ativo } = action.payload;
      const profissionais = state.profissionais.map((profissional) => {
        if (profissional.id === id) {
          return { ...profissional, ativo };
        }
        return profissional;
      });
      return {
        ...state,
        profissionais,
      };
    case SET_STATUS_USUARIO:
      const { id: usuarioId, ativo: usuarioAtivo } = action.payload;
      const usuarios = state.usuarios.map((usuario) => {
        if (usuario.id === usuarioId) {
          return { ...usuario, ativo: usuarioAtivo };
        }
        return usuario;
      });
      return {
        ...state,
        usuarios,
      };
    default:
      return state;
  }
};

const store = createStore(reducer);

export default store;
