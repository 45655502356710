import React, { useEffect, useState } from 'react';
import { Container } from '@mui/system';
import * as Yup from 'yup';
import '../../css/Cadastros/CadProfissional.modules.css';
import Navbar from '../NavBar';
import api from '../../service/api';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { cpf as validateCpf } from 'cpf-cnpj-validator';

const CadProfissional = () => {
  const [especialidades, setEspecialidades] = useState([]);
  const [selectedEspecialidade, setSelectedEspecialidade] = useState('');

  const profissionalSelecionado = useSelector(state => state.profissionalSelecionado);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [id, setId] = useState('');
  const [nome, setNome] = useState('');
  const [cpf, setCpf] = useState('');
  const [numConselhos, setNumConselhos] = useState('');
  const [paisEmissor, setPaisEmissor] = useState('');
  const [pessoaId, setPessoaId] = useState('');
  const [ufConselhoId, setUfConselhoId] = useState('');
  const [especialidadeId, setEspecialidadeId] = useState('');
  const [conselhoId, setConselhoId] = useState('');
  const [ativo, setAtivo] = useState('');

  const [nomeErrors, setNomeErrors] = useState('');
  const [cpfErrors, setCpfErrors] = useState('');
  const [numConselhosErrors, setNumConselhosErrors] = useState('');
  const [paisEmissorErrors, setPaisEmissorErrors] = useState('');
  const [pessoaIdErrors, setPessoaIdErrors] = useState('');
  const [ufConselhoIdErrors, setUfConselhoIdErrors] = useState('');
  const [especialidadeIdErrors, setEspecialidadeIdErrors] = useState('');
  const [conselhoIdErrors, setConselhoIdErrors] = useState('');
  const [isCPFValid, setIsCPFValid] = useState(true);

  useEffect(() => {
    if (profissionalSelecionado) {
      setId(profissionalSelecionado.id);
      setNome(profissionalSelecionado.nome_fonetico);
      setCpf(profissionalSelecionado.cpf);
      setNumConselhos(profissionalSelecionado.numero_conselhos);
      setPaisEmissor(profissionalSelecionado.pais_emissor);
      setPessoaId(profissionalSelecionado.pessoa_id);
      setUfConselhoId(profissionalSelecionado.uf_conselho_id);
      setEspecialidadeId(profissionalSelecionado.especialidade_id);
      setConselhoId(profissionalSelecionado.conselho_id);
      setAtivo(profissionalSelecionado.ativo);
    }
  }, [profissionalSelecionado]);


  useEffect(() => {
    const fetchEspecialidades = async () => {
      try {
        const response = await api.get('/Especialidades/Listar');
        setEspecialidades(response.data.resultado);
      } catch (error) {
        console.error(error);
      }
    };

    fetchEspecialidades();
  }, []);

  const validationSchema = Yup.object().shape({
    pessoaId: Yup.string().required('Campo obrigatório'),
    conselhoId: Yup.string().required('Campo obrigatório'),
    numConselhos: Yup.string()
      .required('Campo obrigatório')
      .max(10, 'O número do conselho deve ter no máximo 10 caracteres'),
    ufConselhoId: Yup.string().required('Campo obrigatório'),
    nome: Yup.string().required('Campo obrigatório'),
    cpf: Yup.string()
      .required('Campo obrigatório')
      .min(11, 'O CPF deve ter 11 caracteres'),
    paisEmissor: Yup.string()
      .required('Campo obrigatório'),
    especialidadeId: Yup.string().required('Campo obrigatório'),
  });

  const isValidCPF = (cpf) => {
    return validateCpf.isValid(cpf);
  };

  const formatCpf = (cpf) => {
    const cpfDigits = cpf.replace(/\D/g, ''); // Remove todos os caracteres não numéricos
    const match = cpfDigits.match(/^(\d{3})(\d{3})(\d{3})(\d{2})$/);
    if (match) {
      return `${match[1]}.${match[2]}.${match[3]}-${match[4]}`;
    }
    return cpf;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      await validationSchema.validate({
        nome, cpf, numConselhos, paisEmissor, pessoaId,
        ufConselhoId, especialidadeId, conselhoId
      }, { abortEarly: false });

      const isCPFValid = isValidCPF(cpf);
      setIsCPFValid(isCPFValid);

      if (!isCPFValid) {
        console.log('teste');
        return;
      }

      const dataAlterar = {
        id: id,
        nome_fonetico: nome,
        cpf: cpf,
        numero_conselhos: numConselhos,
        pais_emissor: paisEmissor.toString(),
        pessoa_id: pessoaId,
        uf_conselho_id: ufConselhoId,
        especialidade_id: especialidadeId,
        conselho_id: conselhoId,
        ativo: ativo
      };

      const dataSalvar = {
        nome_fonetico: nome,
        cpf: cpf,
        numero_conselhos: numConselhos,
        pais_emissor: paisEmissor.toString(),
        pessoa_id: pessoaId,
        uf_conselho_id: ufConselhoId,
        especialidade_id: especialidadeId,
        conselho_id: conselhoId,
        ativo: true
      };

      if (profissionalSelecionado) {
        await api.put('/Profissionais/Alterar', dataAlterar);
      } else {
        await api.post('/Profissionais/Salvar', dataSalvar);
      }

      console.log('alterar: ' + dataAlterar.uf_conselho_id);
      console.log('salvar: ' + dataSalvar.uf_conselho_id);

      setId('');
      setNome('');
      setCpf('');
      setNumConselhos('');
      setPaisEmissor('');
      setPessoaId('');
      setUfConselhoId('');
      setEspecialidadeId('');
      setConselhoId('');

      navigate('/profissional');
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const validationErrors = {};

        error.inner.forEach((innerError) => {
          validationErrors[innerError.path] = innerError.message;
        });

        setNomeErrors(validationErrors.nome || '');
        setCpfErrors(validationErrors.cpf || '');
        setNumConselhosErrors(validationErrors.numConselhos || '');
        setPaisEmissorErrors(validationErrors.paisEmissor || '');
        setPessoaIdErrors(validationErrors.pessoaId || '');
        setUfConselhoIdErrors(validationErrors.ufConselhoId || '');
        setEspecialidadeIdErrors(validationErrors.especialidadeId || '');
        setConselhoIdErrors(validationErrors.conselhoId || '');
      } else {
        console.error(error);
      }
    }
  };

  return (
    <div>
      <Navbar />
      <Container maxWidth="md">
        <div className="tituloCadastro">
          <h1>Cadastro de Profissionais</h1>
        </div>
        <form onSubmit={handleSubmit} className="container">
          <div>
            <label className="lblProfissional">Código:</label>
            <input
              readOnly
              className="inputCodigo inputEspecialidade"
              type="text"
              name="codigo"
              value={id}
            />
            <br />
          </div>
          <div>
            <label className="lblProfissional">Pessoa:</label>
            <select
              className={`selectProfissional ${pessoaIdErrors ? 'error' : ''}`}
              type="text"
              value={pessoaId}
              onChange={(event) => {
                setPessoaId(event.target.value);
                setPessoaIdErrors('');
              }}
            >
              <option value="">Selecione uma opção</option>
              <option value="1">Gabriel Lanza</option>
              <option value="2">João Hitman</option>
            </select>
            <br />
            {pessoaIdErrors && <span className='spanErro'>{pessoaIdErrors}</span>}
          </div>

          <div>
            <label className="lblProfissional">Conselho:</label>
            <select
              className={`selectProfissional ${conselhoIdErrors ? 'error' : ''}`}
              name="conselho"
              value={conselhoId}
              onChange={(event) => {
                setConselhoId(event.target.value);
                setConselhoIdErrors('');
              }}
            >
              <option value="">Selecione uma opção</option>
              <option value="1">CRM</option>
            </select>
            <br />
            {conselhoIdErrors && <span className='spanErro'>{conselhoIdErrors}</span>}
          </div>
          <div className="inputGroup">
            <div className="inputGroupItem">
              <label className="lblProfissional">Número Conselho:</label>
              <input
                className={`inputProfissional ${numConselhosErrors ? 'error' : ''}`}
                type="text"
                name="numConselhos"
                value={numConselhos}
                onChange={(event) => {
                  setNumConselhos(event.target.value);
                  setNumConselhosErrors('');
                }}
              />
              <br />
              {numConselhosErrors && <span className='spanErro'>{numConselhosErrors}</span>}
            </div>
            <div className="inputGroupItem">
              <label className="lblProfissional">UF Conselho:</label>
              <select
                className={`selectProfissional ${ufConselhoIdErrors ? 'error' : ''}`}
                name="ufConselhoId"
                value={ufConselhoId}
                onChange={(event) => {
                  setUfConselhoId(event.target.value);
                  setUfConselhoIdErrors('');
                }}
              >
                <option value="">Selecione um estado</option>
                <option value="1">São Paulo</option>
              </select>
              <br />
              {ufConselhoIdErrors && <span className='spanErro'>{ufConselhoIdErrors}</span>}
            </div>
          </div>
          <div>
            <label className="lblProfissional">País emissor do conselho:</label>
            <select
              className={`selectProfissional ${paisEmissorErrors ? 'error' : ''}`}
              name="paisEmissor"
              value={paisEmissor}
              onChange={(event) => {
                setPaisEmissor(event.target.value);
                setPaisEmissorErrors('');
              }}
            >
              <option value="">Selecione um país</option>
              <option value="Antígua e Barbuda">Antígua e Barbuda</option>
              <option value="Argentina">Argentina</option>
              <option value="Bahamas">Bahamas</option>
              <option value="Barbados">Barbados</option>
              <option value="Belize">Belize</option>
              <option value="Bolívia">Bolívia</option>
              <option value="Brasil">Brasil</option>
              <option value="Canadá">Canadá</option>
              <option value="Chile">Chile</option>
              <option value="Colômbia">Colômbia</option>
              <option value="Costa Rica">Costa Rica</option>
              <option value="Cuba">Cuba</option>
              <option value="Dominica">Dominica</option>
              <option value="Equador">Equador</option>
              <option value="El Salvador">El Salvador</option>
              <option value="Estados Unidos">Estados Unidos</option>
              <option value="Granada">Granada</option>
              <option value="Guatemala">Guatemala</option>
              <option value="Guiana">Guiana</option>
              <option value="Haiti">Haiti</option>
              <option value="Honduras">Honduras</option>
              <option value="Jamaica">Jamaica</option>
              <option value="México">México</option>
              <option value="Nicarágua">Nicarágua</option>
              <option value="Panamá">Panamá</option>
              <option value="Paraguai">Paraguai</option>
              <option value="Peru">Peru</option>
              <option value="República Dominicana">República Dominicana</option>
              <option value="Santa Lúcia">Santa Lúcia</option>
              <option value="São Cristóvão e Nevis">São Cristóvão e Nevis</option>
              <option value="São Vicente e Granadinas">São Vicente e Granadinas</option>
              <option value="Suriname">Suriname</option>
              <option value="Trinidad e Tobago">Trinidad e Tobago</option>
              <option value="Uruguai">Uruguai</option>
              <option value="Venezuela">Venezuela</option>
            </select>
            <br />
            {paisEmissorErrors && <span className='spanErro'>{paisEmissorErrors}</span>}
          </div>
          <div className="inputGroup">
            <div className="inputGroupItem">
              <label className="lblProfissional">Nome Fonético:</label>
              <input
                className={`inputProfissional ${nomeErrors ? 'error' : ''}`}
                placeholder='Digite o nome'
                type="text"
                name="nome"
                value={nome}
                onChange={(event) => {
                  setNome(event.target.value);
                  setNomeErrors('');
                }}
              />
              <br />
              {nomeErrors && <span className='spanErro'>{nomeErrors}</span>}
            </div>
            <div className="inputGroupItem">
              <label className="lblProfissional">CPF:</label>
              <input
                className={`inputProfissional ${cpfErrors || !isCPFValid ? 'error' : ''}`}
                type="text"
                name="cpf"
                value={formatCpf(cpf)}
                maxLength={14}
                onChange={(event) => {
                  setCpf(event.target.value.replace(/\D/g, ''));
                  setCpfErrors('');
                }}
              />
              <br />
              {cpfErrors && <span className='spanErro'>{cpfErrors}</span>}
              {!cpfErrors && !isCPFValid && <span className='spanErro'>CPF inválido</span>}
            </div>

          </div>
          <div>
            <label className="lblProfissional">Especialidade:</label>
            <select
              className={`selectProfissional ${especialidadeIdErrors ? 'error' : ''}`}
              name="selectedEspecialidade"
              value={especialidadeId}
              onChange={(event) => {
                setEspecialidadeId(event.target.value);
                setEspecialidadeIdErrors('');
              }}
            >
              <option value="">Selecione uma opção</option>
              {especialidades.length > 0 && especialidades.map((especialidade) => (
                <option key={especialidade.id} value={especialidade.id}>
                  {especialidade.descricao}
                </option>
              ))}
            </select>
            <br />
            {especialidadeIdErrors && (
              <span className="spanErro">{especialidadeIdErrors}</span>
            )}
          </div>
          <div className="centralizaCampos">
            <button onClick={handleSubmit} className="btnSalvarProfissional" type="submit">SALVAR</button>
          </div>
        </form>
      </Container>
    </div>
  );
};

export default CadProfissional;
