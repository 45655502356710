import React from 'react';
import Navbar from '../components/NavBar';
import ListaUsuario from '../components/Listas/ListaUsuario';

const Usuario = () => {
  return (
    <div>
      <div>
        <Navbar />
        <ListaUsuario />
      </div>
    </div>
  )
}

export default Usuario