import React from 'react';
import Navbar from '../components/NavBar';
import ListaProfissional from '../components/Listas/ListaProfissional';

const Profissional = () => {
  return (
    <div>
      <div>
        <Navbar />
        <ListaProfissional />
      </div>
    </div>
  )
}

export default Profissional