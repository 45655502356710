import React from 'react'
import afipplogo from '../assets/afipp-logo.png';
import '../css/Navbar.modules.css';


const LogoNav = () => {
  return (
    <div className='centraliza'>
        <img src={afipplogo} className='tamanhoImgNav'/>
    </div>
  )
}

export default LogoNav