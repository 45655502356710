import React from 'react';
import Navbar from '../components/NavBar';

const Inicial = () => {
    return (
        <div>
            <Navbar />
        </div>
    )
}

export default Inicial