import React, { useState, useEffect, useRef } from 'react';
import '../css/Navbar.modules.css';
import LogoNav from './LogoNav';

const Navbar = () => {
  const [mostrarDropdown, setMostrarDropdown] = useState(false);
  const [mostrarMenu, setMostrarMenu] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setMostrarDropdown(!mostrarDropdown);
  };

  const toggleMenu = () => {
    setMostrarMenu(!mostrarMenu);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setMostrarDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  return (
    <div className="navbar">
      <div className="navbar-content">
        <LogoNav className="logoNav" />
        <div
          className={`navbar-toggle ${mostrarMenu ? 'hidden' : ''}`}
          onClick={toggleMenu}
        >
          <div className="menu-icon"></div>
        </div>
        <ul className={`menus ${mostrarMenu ? 'active' : ''}`} ref={dropdownRef}>
          <li className="menu">
            <p>AGENDA</p>
          </li>
          <li className="menu">
            <p>PRONTUÁRIO</p>
          </li>
          <li className="menu">
            <p>RELATÓRIOS</p>
          </li>
          <li className={`menu2 nav-item ${mostrarDropdown ? 'active' : ''}`}>
            <div
              className="nav-link dropdown-toggle"
              onClick={toggleDropdown}
            >
              CADASTRO<br />GERAL
            </div>
            {mostrarDropdown && (
              <ul className="dropdown-menu">
                <li><a className="dropdown-item" href="/especialidades">Especialidades</a></li>
                <li><a className="dropdown-item" href="/profissional">Profissional</a></li>
                <li><a className="dropdown-item" href="/usuario">Usuário</a></li>
              </ul>
            )}
          </li>
        </ul>
  
        {!mostrarMenu && (
          <div className="logout">
            <div className="usuarioLogado">USUÁRIO</div>
            <button className="btnSair">SAIR</button>
          </div>
        )}
      </div>
    </div>
  );
  
}

export default Navbar;
