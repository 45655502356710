import React, { useState, useEffect } from 'react';
import { Container } from '@mui/system';
import { useSelector, useDispatch } from 'react-redux';
import Navbar from '../NavBar';
import api from '../../service/api';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import '../../css/Cadastros/CadUsuario.modules.css';

const CadUsuario = () => {
  const usuarioSelecionado = useSelector(state => state.usuarioSelecionado);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [profissionais, setProfissionais] = useState([]);
  const [selectedProfissional, setSelectedProfissional] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);
  const [id, setId] = useState('');
  const [usuario, setUsuario] = useState('');
  const [senha, setSenha] = useState('');
  const [profissionalId, setProfissionalId] = useState('');

  const [usuarioError, setUsuarioError] = useState('');
  const [senhaError, setSenhaError] = useState('');
  const [profissionalIdError, setProfissionalIdError] = useState('');

  const validationSchema = Yup.object().shape({
    usuario: Yup.string()
      .required('O campo Usuário é obrigatório')
      .min(3, 'O Usuário deve ter no mínimo 3 caracteres')
      .max(20, 'O Usuário deve ter no máximo 20 caracteres'),
    senha: Yup.string()
      .required('O campo Senha é obrigatório')
      .min(8, 'A Senha deve ter no mínimo 8 caracteres')
      .max(30, 'A Senha deve ter no máximo 30 caracteres'),
    profissionalId: Yup.string().required('Escolha um profissional'),
  });
  

  useEffect(() => {
    if (usuarioSelecionado) {
      setId(usuarioSelecionado.id);
      setUsuario(usuarioSelecionado.usuario);
      setSenha('');
      setProfissionalId(usuarioSelecionado.profissional_id);
      setIsAdmin(usuarioSelecionado.admin);
    }
  }, [usuarioSelecionado]);

  useEffect(() => {
    const fetchProfissional = async () => {
      try {
        const response = await api.get('/Profissionais/Listar');
        setProfissionais(response.data.resultado);
      } catch (error) {
        console.error(error);
      }
    };

    fetchProfissional();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      await validationSchema.validate({ usuario, senha, profissionalId }, { abortEarly: false });
      
      const dataAlterar = {
        id: id,
        usuario: usuario,
        senha: senha,
        profissional_id: profissionalId,
        admin: isAdmin,
        ativo: true
      };

      const dataSalvar = {
        usuario: usuario,
        senha: senha,
        profissional_id: profissionalId,
        admin: isAdmin,
        ativo: true
      };

      if (usuarioSelecionado) {
        await api.put('/Usuarios/Alterar', dataAlterar);
      } else {
        await api.post('/Usuarios/Salvar', dataSalvar);
      }

      console.log('alterar: ' + dataAlterar);
      console.log('salvar: ' + dataSalvar);

      setId('');
      setUsuario('');
      setSenha('');
      setProfissionalId('');

      navigate('/usuario');
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const validationErrors = {};

        error.inner.forEach((innerError) => {
          validationErrors[innerError.path] = innerError.message;
        });

        setUsuarioError(validationErrors.usuario || '');
        setSenhaError(validationErrors.senha || '');
        setProfissionalIdError(validationErrors.profissionalId || '');
      } else {
        console.error(error);
      }
    }
  };

  return (
    <div>
      <Navbar />
      <Container maxWidth="md">
        <div className="tituloCadastro">
          <h1>Cadastro de Usuários</h1>
        </div>
        <form onSubmit={handleSubmit}>
          <div>
            <label className="lblUsuario">Código:</label>
            <input readOnly className="inputUsuario inputCodigo" type="text" value={id} onChange={(event) => setId(event.target.value)} />
            <br />
            <span className="spanErro"></span>
          </div>
          <div className="inputGroup">
            <div className="inputGroupItem">
              <label className="lblUsuario">Usuário:</label>
              <input 
                placeholder='Digite um usuário' 
                className={`inputUsuario ${usuarioError ? 'error' : ''}`} 
                type="text" 
                value={usuario} 
                onChange={(event) => {
                  setUsuario(event.target.value);
                  setUsuarioError('');
                }}
              />
              <br />
              {usuarioError && <span className="spanErro">{usuarioError}</span>}
            </div>
            <div className="inputGroupItem">
              <label className="lblUsuario">Senha:</label>
              <input 
                placeholder='Digite uma senha' 
                className={`inputUsuario ${senhaError ? 'error' : ''}`} 
                type="password" 
                value={senha} 
                onChange={(event) => {
                  setSenha(event.target.value);
                  setSenhaError('');
                }}
              />
              <br />
              {senhaError && <span className="spanErro">{senhaError}</span>}
            </div>
          </div>
          <div className="inputGroup">
            <div className="inputGroupItem">
              <label className="lblProfissional">Profissional:</label>
              <select
                className={`selectUsuario ${profissionalIdError ? 'error' : ''}`}
                name="selectedEspecialidade"
                value={profissionalId}
                onChange={(event) => {
                  setProfissionalId(event.target.value);
                  setProfissionalIdError('');
                }}
              >
                <option value="">Selecione uma opção</option>
                {profissionais.length > 0 && profissionais.map((profissional) => (
                  <option key={profissional.id} value={profissional.id}>
                    {profissional.nome_fonetico}
                  </option>
                ))}
              </select>
              <br />
              {profissionalIdError && <span className="spanErro">{profissionalIdError}</span>}
            </div>
            <div className="inputGroupItem">
              <label className="lblUsuario">Admin:</label>
              <input 
                className="checkboxAdmin" 
                type="checkbox" 
                checked={isAdmin} 
                onChange={(event) => {
                  setIsAdmin(event.target.checked);
                }}
              />
              <br />
              <span className="spanErro"></span>
            </div>
          </div>
          <div className="centralizaCampos">
            <button className="btnSalvarUsuario" type="submit">SALVAR</button>
          </div>
        </form>
      </Container>
    </div>
  );
};

export default CadUsuario;
