import React, { useEffect, useState } from 'react';
import { Container, margin } from '@mui/system';
import { Link, useNavigate } from 'react-router-dom';
import '../../css/Listas/ListaUsuario.modules.css';
import { useSelector, useDispatch } from 'react-redux';
import store from '../../service/store';
import api from '../../service/api';

const fetchUsuarios = async (dispatch) => {
  try {
    const response = await api.get('/Usuarios/Listar');
    const dadosUsuarios = response.data.resultado;
    dispatch({ type: 'SET_USUARIOS', payload: dadosUsuarios });
  } catch (error) {
    console.error(error);
  }
};

const ListaUsuarios = () => {

  const usuarios = useSelector(state => state.usuarios);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    fetchUsuarios(dispatch);
  }, []);

  useEffect(() => {
    dispatch({ type: 'SET_USUARIO_SELECIONADO', payload: null });
  }, []);

  const handleEditarUsuario = (usuario) => {
    dispatch({ type: 'SET_USUARIO_SELECIONADO', payload: usuario });
    navigate('/cadastro-usuario');
  };

  const handleDesabilitarUsuario = async (usuario) => {
    try {
      const idUsuario = usuario.id;
      const novoStatus = usuario.ativo ? 'inativo' : 'ativo';

      await api.delete(`/Usuarios/Ativar/Inativar?id=${idUsuario}`);

      dispatch({
        type: 'SET_STATUS_USUARIO',
        payload: {
          id: idUsuario,
          ativo: novoStatus === 'ativo',
        },
      });
      fetchUsuarios(dispatch);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <Container maxWidth="xd">
        <div className="tituloLista">
          <h1>Lista de Usuários</h1>
        </div>
        <div className="btnCadastro">
          <Link to="/cadastro-usuario" className="btnCadUsuario">Cadastrar Usuário</Link>
        </div>
        <br />
        <table className="tabelaUsuarios">
          <thead>
            <tr>
              <th>Usuário</th>
              <th>Tipo de usuário</th>
              <th>Status</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            {usuarios.map((usuario) => (
              <tr key={usuario.id}>
                <td>{usuario.usuario}</td>
                <td>
                  {usuario.admin ? "Administrador" : "Usuário"}
                </td>
                <td>
                  {usuario.ativo ? "Ativo" : "Desabilitado"}
                </td>
                <td>
                  <button className="btnEditar" onClick={() => handleEditarUsuario(usuario)}>
                    Editar
                  </button>
                  <button className="btnExcluir" onClick={() => handleDesabilitarUsuario(usuario)}>Ativo/Desabilitado</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Container>
      <br />
      <br />
    </div>
  );
};

export default ListaUsuarios;
