import React, { useEffect } from 'react';
import { Container } from '@mui/system';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import api from '../../service/api';
import '../../css/Listas/ListaEspecialidades.modules.css';

const ListaEspecialidades = () => {
  const especialidades = useSelector(state => state.especialidades);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchEspecialidades = async () => {
      try {
        const response = await api.get('/Especialidades/Listar');
        const dadosEspecialidades = response.data.resultado;
        dispatch({ type: 'SET_ESPECIALIDADES', payload: dadosEspecialidades });
      } catch (error) {
        console.error(error);
      }
    };

    fetchEspecialidades();
  }, []);

  useEffect(() => {
    dispatch({ type: 'SET_ESPECIALIDADE_SELECIONADA', payload: null });
  }, []);

  const handleEditarEspecialidade = (especialidade) => {
    dispatch({ type: 'SET_ESPECIALIDADE_SELECIONADA', payload: especialidade });
    navigate('/cadastro-especialidades');
  };


  return (
    <div>
      <Container maxWidth="xd">
        <div className="tituloLista">
          <h1>Lista de Especialidades</h1>
        </div>

        <div className="btnCadastro">
          <Link to="/cadastro-especialidades" className="btnCadEspecialidades">Cadastrar Especialidade</Link>
        </div>
        <br />
        <div className="tabelaEspecialidades">
          <table>
            <thead>
              <tr>
                <th>Código</th>
                <th>Especialidade</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {especialidades.map((especialidade) => (
                <tr key={especialidade.id}>
                  <td>{especialidade.id}</td>
                  <td>{especialidade.descricao}</td>
                  <td>
                    <button className="btnEditar" onClick={() => handleEditarEspecialidade(especialidade)}>
                      Editar
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Container>
      <br />
      <br />
    </div>
  );
};

export default ListaEspecialidades;
