import React from 'react';
import Navbar from '../components/NavBar';
import ListaEspecialidades from '../components/Listas/ListaEspecialidades';

const Especialidades = () => {
  return (
    <div>
      <div>
        <Navbar />
        <ListaEspecialidades />
      </div>
    </div>
  )
}

export default Especialidades