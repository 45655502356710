import React, { useState, useEffect } from 'react';
import { Container } from '@mui/system';
import { useSelector, useDispatch } from 'react-redux';
import Navbar from '../NavBar';
import '../../css/Cadastros/CadEspecialidades.modules.css';
import api from '../../service/api';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

const CadEspecialidades = () => {
  const especialidadeSelecionada = useSelector(state => state.especialidadeSelecionada);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [codigo, setCodigo] = useState('');
  const [descricao, setDescricao] = useState('');
  const [descricaoError, setDescricaoError] = useState('');

  const validationSchema = Yup.object().shape({
    descricao: Yup.string()
      .required('O campo Especialidade é obrigatório')
      .min(3, 'A descrição deve ter no mínimo 3 caracteres')
      .max(30, 'A descrição deve ter no máximo 30 caracteres'),
  });
  

  useEffect(() => {
    if (especialidadeSelecionada) {
      setCodigo(especialidadeSelecionada.id);
      setDescricao(especialidadeSelecionada.descricao);
    }
  }, [especialidadeSelecionada]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      await validationSchema.validate({ descricao }, { abortEarly: false });
      const dataAlterar = {
        id: codigo,
        descricao: descricao
      };

      const dataSalvar = {
        descricao: descricao
      };

      if (especialidadeSelecionada) {
        await api.put('/Especialidades/Alterar', dataAlterar);
      } else {
        await api.post('/Especialidades/Salvar', dataSalvar);
      }

      setCodigo('');
      setDescricao('');

      navigate('/especialidades');
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const validationErrors = {};

        error.inner.forEach((innerError) => {
          validationErrors[innerError.path] = innerError.message;
        });

        setDescricaoError(validationErrors.descricao || '');
      } else {
        console.error(error);
      }
    }
  };

  return (
    <div>
      <Navbar />
      <Container maxWidth="md">
        <div className='tituloCadastro'>
          <h1>Cadastro de Especialidades</h1>
        </div>
        <div className='btnLista'>
          <button></button>
        </div>
        <form onSubmit={handleSubmit}>
          <div>
            <label className='lblEspecialidade'>
              Código:
            </label>
            <input readOnly className="inputEspecialidade inputCodigo" type="text" value={codigo} onChange={(event) => setCodigo(event.target.value)} />
            <br />
          </div>
          <div>
            <label className='lblEspecialidade'>
              Especialidade:
            </label>
            <input
              className={`inputEspecialidade ${descricaoError ? 'error' : ''}`}
              placeholder='Digite a especialidade'
              type="text"
              value={descricao}
              onChange={(event) => {
                setDescricao(event.target.value);
                setDescricaoError('');
              }}
            />

            <br />
            {descricaoError && <span className='spanErro'>{descricaoError}</span>}
          </div>
          <div className='centralizaCampos'>
            <button className="btnSalvarEspecialidade" type="submit">SALVAR</button>
          </div>
        </form>
      </Container>
    </div>
  );
};

export default CadEspecialidades;
